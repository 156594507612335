import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import AccountCircle from '@mui/icons-material/AccountCircle';
import axios from 'axios';

const EntryForm = ({ entryFormOpen, setEntryFormOpen }) => {

  const [suggestion, setSuggestion] = useState({ name: '', address: '', additionalInfos: '' });
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [requiredNameError, setRequiredNameError] = useState(false);
  const [requiredAddressError, setRequiredAddressError] = useState(false);

  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handleClose = () => {
    setEntryFormOpen(false);
  };

  const validateForm = (entry) => {
    //if no suggestion is given, return false
    if (entry === '') {
      return false;
    }
  };

  //send form to strapi with endpoint /suggestion
  const handleFormSend = () => {
    if (validateForm(suggestion.name)) {
      setRequiredNameError(true);
      console.log('Name is required');
      console.log(suggestion);
      return;
    }
    if (validateForm(suggestion.address)) {
      setRequiredAddressError(true);
      console.log('Address is required');
      return;
    }

    //create postBody
    const postBody = {
      "data": {
        "akteurName": suggestion.name,
        "akteurAddress": suggestion.address,
        "akteurInfos": suggestion.additionalInfos,
      }
    };
    sendSuggestionToStrapi(postBody)
      .then(() => {
        setSuccessOpen(true); // Display success alert
        setTimeout(() => {
          setSuccessOpen(false); // Close success alert after 3 seconds
          handleClose(); // Close the dialog
        }, 3000);
      })
      .catch((error) => {
        console.error('Error sending suggestion:', error);
        setErrorOpen(true); // Display error alert
        setTimeout(() => {
          setErrorOpen(false); // Close success alert after 3 seconds
          handleClose(); // Close the dialog
        }, 3000);
      });
  };

  const sendSuggestionToStrapi = (suggestionData) => {
    const strapi_endpoint = process.env.REACT_APP_STRAPI_API_URL + "/remap-offer-entries";
    const strapi_token = process.env.REACT_APP_STRAPI_API_TOKEN;

    return axios.post(strapi_endpoint, suggestionData, {
      headers: {
        Authorization: `Bearer ${strapi_token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return (
    <Dialog open={entryFormOpen} onClose={() => setEntryFormOpen(false)} >
      <DialogTitle>Eintrag vorschlagen</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Du hast das Gefühl, auf der Karte fehlt ein Akteur? Dann gib hier so viele Infos wie du weißt. Das Angebot wird dann bestimmt bald auf der Karte auftauchen! Vielen Dank für deine Mithilfe!
        </DialogContentText>

        <TextField
          autoFocus
          required
          margin="dense"
          id="form_name"
          label="Wie heißt der Akteur/das Unternehmen/der Verein?" // Add asterisk to indicate required field
          helperText="z.B. Verschenkeladen im Westen e.V."
          type="text"
          fullWidth
          variant="standard"
          value={suggestion.name}
          error={requiredNameError}
          onChange={(e) => {
            setSuggestion({ ...suggestion, name: e.target.value });
            setRequiredNameError(false);
          }}
        />
        <TextField
          autoFocus
          required
          margin="dense"
          id="form_address"
          label="Wie ist die Adresse?" // Add asterisk to indicate required field
          helperText="z.B. An der Mühle 23, 04324 Döbeln"
          type="text"
          fullWidth
          variant="standard"
          value={suggestion.address}
          error={requiredAddressError}
          onChange={(e) => {
            setSuggestion({ ...suggestion, address: e.target.value });
            setRequiredAddressError(false);
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="form_additional_infos"
          label="Hast du zusätzliche Infos?"
          helperText="z.B. Website, Öffnungszeiten, E-Mail-Adresse, Ansprechpartner"
          multiline
          type="textarea"
          rows={4}
          fullWidth
          variant="standard"
          onChange={(e) => {
            setSuggestion({ ...suggestion, additionalInfos: e.target.value });
          }}
        />

        {successOpen &&
          <Alert severity="success" onClose={handleSuccessClose}>
            Dein Vorschlag wurde erfolgreich versendet. Vielen Dank für deine Mithilfe!
          </Alert>
        }
        {errorOpen &&
          <Alert severity="error" onClose={handleErrorClose}>
            Beim Versenden deines Vorschlages ist ein Fehler aufgetreten. Bitte versuche es später erneut.
          </Alert>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setEntryFormOpen(false)}>Abbrechen</Button>
        <Button onClick={handleFormSend}>Absenden</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EntryForm;