import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import { useSearchParams } from "react-router-dom";
import AppContext from "../../context/AppContext";
import Map from '../Map';
import Search from '../Search';
import MobileSearch from '../MobileSearch';
import ResultsList from '../ResultsList';
import MobileList from '../ResultsList/mobilelist';
import FavoritesList from '../FavoritesList';
import SmallMobileList from '../ResultsList/smallmobilelist';
import './index.css';
import reportWebVitals from '../../reportWebVitals';
import axios from "axios";
import lklzerowastelogo from '../../media/images/logo-zero-waste-color.svg';
import remap_logo from '../../media/images/remap_turned.png';
import remap from '../../media/images/remap.png';
import map_image from '../../media/images/map-and-navigation.png';
import KELL from '../../media/images/KELL.png';
import AFU from '../../media/images/AFU.png';
import Sachsen from '../../media/images/Sachsen.png';
import reusereduce from '../../media/images/reducereuserecyclerethink.png';
import { Collapse, IconButton, Typography, Box, Paper, useMediaQuery, BottomNavigation, BottomNavigationAction, Button, Dialog, Slide, ListItemText, ListItem, Divider, ListItemIcon, ListItemButton, List, SwipeableDrawer, Stack, Skeleton, Backdrop, CircularProgress, DialogTitle, DialogContent, DialogContentText, DialogActions, Card, CardHeader, CardMedia, CardContent, Popover } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import {
  ArrowCircleLeft as ArrowCircleLeftIcon,
  ArrowCircleRight as ArrowCircleRightIcon,
  Apps as AppsIcon,
  Favorite as FavoriteIcon,
  Radar as RadarIcon,
  Search as SearchIcon,
  Add as AddIcon,
  More as MoreIcon,
  Menu as MenuIcon,
  Restore as RestoreIcon,
  LocationOn as LocationOnIcon,
  Close as CloseIcon,
  HighlightOff as HighlightOffIcon,
  Info as InfoIcon,
  Handshake as HandshakeIcon,
  Language as LanguageIcon,
  MoreHoriz as MoreHorizIcon,
  Feedback as FeedbackIcon,
  Savings as SavingsIcon,
} from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import defaultTheme from '../../style/theme';
import LogRocket from 'logrocket';
import DetailedView from '../DetailedView';
import DetailedMobileView from '../DetailedView/mobile';
import SmallMobileView from '../DetailedView/small_mobile';
import EntryForm from '../EntryForm'
import ResultsFilter from '../ResultsFilter';
import RotatedText from '../../RotatedText';
import useResizeObserver from '@react-hook/resize-observer';
import ResizeObserver from 'rc-resize-observer';
import { render } from 'react-dom';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import { createBoundingBox } from '../BoundingBox';
import { useTour } from '@reactour/tour';
import { set } from 'lodash';
import kellmap from '../../media/images/KELLmap_offers.png';
require("typeface-syne");
require("typeface-inter");
require("typeface-noto-sans");
require("typeface-roboto");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerBleeding = 112;

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
  bottom: 50
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const getRandomScribbleImage = () => {
  const images = require.context('../../media/images/scribbles', false, /\.(png|svg)$/);
  const imageKeys = images.keys();
  const randomIndex = Math.floor(Math.random() * imageKeys.length);
  return images(imageKeys[randomIndex]);
};


const Site1 = () => {
  const [queryParameters] = useSearchParams();

  const userColors = {
    primary: queryParameters.get('primaryColor'), // Adjust parameter names as needed
    secondary: queryParameters.get('secondaryColor'),
    tertiary: queryParameters.get('thirdColor'),
  };

  //if queryParameters.get('logo') is false, then logo is not shown

  const showLogo = queryParameters.get('logo') === 'false' ? false : true;

  const customData = {
    userLocation: queryParameters.get('userLocation'),
    userLocationLat: queryParameters.get('userLocationLat'),
    userLocationLon: queryParameters.get('userLocationLon'),
    radius: queryParameters.get('radius'),
    thing: queryParameters.get('thing'),
    activity: queryParameters.get('activity'),
  }

  const [customTheme, setCustomTheme] = useState(() => {
    return createTheme({
      palette: {
        primary: {
          main: userColors.primary === null ? defaultTheme.palette.primary.main : '#' + userColors.primary,
        },
        secondary: {
          main: userColors.secondary === null ? defaultTheme.palette.secondary.main : '#' + userColors.secondary,
        },
        third: {
          main: userColors.third === null ? defaultTheme.palette.third.main : '#' + userColors.third,
        },
        // Use default values for other colors (quaternary, quinary, senary)
        fourth: defaultTheme.palette.fourth.main,
        fifth: defaultTheme.palette.fifth.main,
        sixth: defaultTheme.palette.sixth.main,
        style1: defaultTheme.palette.style1.main,
        style2: defaultTheme.palette.style2.main,
        style3: defaultTheme.palette.style3.main,
      },
      // Merge other values from your theme configuration as needed
      // For example: typography, overrides, etc.
    });
  });

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isCardOpen, setIsCardOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [preselectedItem, setPreSelectedItem] = useState(null);
  const [searchComponent, setSearchComponent] = useState(true);
  const [isBack, setIsBack] = useState(true);
  const [searchHeight, setSearchHeight] = useState(0);
  const [navHeight, setNavHeight] = useState(0)
  const nav_ref = useRef(null);
  const [listHeight, setListHeight] = useState(0);
  const forwardMapRef = useRef(null);
  const isDesktop = useMediaQuery(customTheme.breakpoints.up('sm'));
  const [open, setOpen] = useState(false);
  const [listIndex, setListIndex] = useState(0);
  const [listClick, setListClick] = useState(false);
  const [openMobileResults, setOpenMobileResults] = useState(false);
  const { setIsOpen, currentStep } = useTour();
  const [userLocation, setUserLocation] = useState(null);
  const trackingConsent = localStorage.getItem('trackingConsent') ? JSON.parse(localStorage.getItem('trackingConsent')) : undefined;
  const [consentDialog, setShowConsentDialog] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [entryFormOpen, setEntryFormOpen] = useState(false);
  const [anchorElFundingPopover, setAnchorElFundingPopover] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({
    'counseling': true,
    'products': true,
    'education': true,
    'projects': true,
    'events': true,
  });

  const handleCategoryToggle = (category) => {
    //access data object and set data[category]["visible"] to the opposite of its current value
    setIsLoading(true);
    setData(prevData => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        visible: !prevData[category].visible
      }
    }));
    //do the same with selectedCategories
    setSelectedCategories(prevState => ({
      ...prevState,
      [category]: !prevState[category]
    }));
    setIsLoading(false);
  };


  const toggleDrawer = (newOpen) => () => {
    setOpenMobileResults(newOpen);
  };
  //mobile only
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpandSearch = () => {
    setIsExpanded((prev) => !prev);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //////

  const startIntro = () => {
    setIsOpen(true);
  };

  const handleClick = (item) => {

    if (item.origin === "map" && !isDesktop) {
      setPreSelectedItem(item);
      item.origin = "";
      return;
    }

    const requestBody = {
      "resource": "NLS.OfferResource",
      "action": "get",
      "params": {
        "id": item.id
      },
      "fields": {
        "title": true,
        "short_description": true,
        "description": true,
        "property_list": true,
        "categories": {
          "title": true,
          "sub_categories": true,
          "category_type": true,
        },
        "contacts": {
          "title": true,
          "contact_urls": {
            "title": true,
            "url": true
          },
          "contact_persons": {
            "name": true,
            "role": true,
            "email": true,
            "phone": true
          }
        },
        "costs": true,
        "requirements": true,
        "curriculum": true,
        "free": true,
        "child_friendly": true,
        "accessible": true,
        "climate_protection": true,
        "event_online": true,
        "event_canceled": true,
        "target_group": true,
        "info_url": true,
        "video_url": true,
        "updated_at": true,
        "image_url": true,
        "offer_type": {
          "display_name": true,
          "key": true,
          "used_attributes": true
        },
        "times": true,
        "locations": {
          "lat": true,
          "long": true,
          "title": true,
          "street": true,
          "city": true,
          "zip": true
        }
      }
    };

    // Make the POST request to the external API
    axios.post(process.env.REACT_APP_DBNS_API_URL, requestBody)
      .then(response => {
        const data_arr = response.data.data;
        //if data_arr has no image_url set image_url to item.image_url
        if (!data_arr.image_url) {
          data_arr.image_url = item.image_url;
        }
        setSelectedItem(data_arr);
        setIsLoading(false);
      })
      .catch(error => {
        setError(error);
        setIsLoading(false);
        console.log(error);
      });

  };

  const handleBackClick = () => {
    setSearchComponent(!searchComponent);
    setIsBack((prev) => !prev);
  };

  const fetchData = (dataObject) => {
    console.log("Fetching data with the following parameters:", dataObject);
    setIsLoading(true);
    setError('');

    if (!isDesktop) {
      handleClose();
    }

    if (firstLoad === true && trackingConsent === undefined) {
      setShowConsentDialog(true);
    }

    console.log(customData);

    if (customData.length !== 0 && firstLoad === true) {
      if (customData.userLocation !== null) {
        dataObject.userLocation = {
          key: 0,
          latitude: parseFloat(customData.userLocationLat),
          longitude: parseFloat(customData.userLocationLon),
          radius: parseInt(customData.radius),
        }
      }
      if (customData.thing !== null) {
        dataObject.selectedThings = customData.thing.split(",");
      }
      if (customData.activity !== null) {
        dataObject.selectedActivities = customData.activity.split(",");
      }
      setFirstLoad(false);
    }


    if (dataObject.userLocation && dataObject.userLocation.key === 1) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("User's location is:", latitude, longitude);
          dataObject.userLocation.latitude = latitude;
          dataObject.userLocation.longitude = longitude;
          const boundingBox = createBoundingBox(dataObject.userLocation.latitude, dataObject.userLocation.longitude, dataObject.userLocation.radius);
          console.log(boundingBox);
          // Request data using the retrieved boundingBox
          requestData(boundingBox, dataObject);
        },
        (error) => {
          console.error(error);
          setIsLoading(false);
        }
      );
    } else if (dataObject.userLocation.length !== 0) {
      const boundingBox = createBoundingBox(dataObject.userLocation.latitude, dataObject.userLocation.longitude, dataObject.userLocation.radius);
      console.log(boundingBox);
      // Request data using the boundingBox
      requestData(boundingBox, dataObject);
    } else {
      // Request data without a boundingBox
      requestData([], dataObject);
    }

    setUserLocation(dataObject.userLocation);
  };


  const requestData = (boundingBox, dataObject) => {
    console.log("Requesting data with the following parameters:", boundingBox, dataObject);

    // construct the request body based on the selected filters
    const requestBody = {
      "resource": "NLS.OfferResource",
      "action": "list",
      "fields": {
        "title": true,
        "short_description": true,
        "description": true,
        "categories": { "title": true },
        "locations": { "lat": true, "long": true, "zip": true, "city": true, "street": true },
        "offer_type": { "display_name": true },
        "image_url": true,
        "info_url": true
      },
      "filters": {
        "categories": [8],
        "page_size": 2000,
        "offer_type_id": null
      }
    };

    // add the selected filters to the request body

    if (Object.keys(boundingBox).length > 0) {
      requestBody.filters.boundingBox = boundingBox.boundingBox;
    }
    if (dataObject.selectedThings.length > 0) {
      requestBody.filters.categories = [...dataObject.selectedThings, ...requestBody.filters.categories];
      requestBody.filters.categories_operator = "or";
    }

    if (dataObject.selectedActivities.length > 0) {
      //add dataObject.selectedActivities to requestBody.filters.categories
      requestBody.filters.categories = [...dataObject.selectedActivities, ...requestBody.filters.categories];
    }

    const offerTypes = {
      "products": 5,
      "education": 1,
      "counseling": 2,
      "projects": 4,
      "events": 3,
    };

    let offerTypeIDs = [];

    for (const [key, value] of Object.entries(dataObject.searchCategories)) {
      if (value === true) {
        if (key === "education") {
          offerTypeIDs.push(offerTypes["counseling"]);
        }
        offerTypeIDs.push(offerTypes[key]);
      }
    }
    setResponseData([]);

    function getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    }

    // Function to make the POST request
    const makePostRequest = async (offerTypeID) => {
      try {
        //add offerType to requestBody.filters.offerTypeID
        requestBody.filters.offer_type_id = offerTypeID;
        if (offerTypeID === 3) {
          requestBody.filters.timespan = dataObject.selectedDateRange;
        }
        const response = await axios.post(`${process.env.REACT_APP_DBNS_API_URL}`, requestBody);
        return response.data;
      } catch (error) {
        console.error('Error making POST request:', error);
        throw error;
      }
    };

    // Function to handle multiple POST requests
    const handleMultipleRequests = async () => {

      try {
        const requests = offerTypeIDs.map((value) => makePostRequest(value));
        const responses = await axios.all(requests);
        //merge all responses into one object, but add a key to each object to identify the offer type
        let mergedObject = {};
        offerTypeIDs.forEach((id, index) => {
          const key = getKeyByValue(offerTypes, id); // Get the key from offerTypes based on numeric ID
          if (key) {
            if (!mergedObject[key]) {
              mergedObject[key] = {}; // Initialize the object if it doesn't exist
              mergedObject[key]["data"] = responses[index].data; // Use the key to assign response data
              mergedObject[key]["visible"] = true;
            }
          }
        });
        if (dataObject.reparaturbonus === false) {
          // Filter out items with category title "Reparaturbonus" anywhere in the categories array in the mergedObject
          
          Object.keys(mergedObject).forEach(categoryName => {
            mergedObject[categoryName]["data"] = mergedObject[categoryName]["data"].filter(item => {
              // Filter out items where none of the categories have title "Reparaturbonus"
              return !item.categories.some(category => category.title === "Reparaturbonus");
            });
          });
        }

        //Filter out all duplicates in the mergedObject
        Object.keys(mergedObject).forEach(categoryName => {
          const uniqueIds = new Set();
          mergedObject[categoryName]["data"] = mergedObject[categoryName]["data"].filter(item => {
            if (!uniqueIds.has(item.id)) {
              uniqueIds.add(item.id);
              return true; // Include the item if it's the first occurrence of its id
            }
            return false; // Exclude the item if its id has already been encountered
          });
        });
        

        // If search has been executed, filter based on search results
        if (dataObject.searchExecuted) {
          const filteredSearchData = Object.keys(mergedObject).reduce((filtered, key) => {
            const filteredItems = mergedObject[key]["data"].filter((item) =>
              dataObject.searchResults.includes(parseInt(item.id))
            );
            if (filteredItems.length > 0) {
              filtered[key] = { ...mergedObject[key], data: filteredItems };
            }
            return filtered;
          }, {});

          setData(filteredSearchData);
          setFilteredData(filteredSearchData);
        } else {
          // If search has not been executed, set mergedObject directly
          setData(mergedObject);
          console.log("Merged Object with Category Title: ", mergedObject);
          setFilteredData(mergedObject);
        }
        setSelectedCategories({
          'counseling': true,
          'products': true,
          'education': true,
          'projects': true,
          'events': true,
        });
        setIsLoading(false);
      } catch (error) {
        console.error('Error handling multiple requests:', error);
      }
    };

    handleMultipleRequests();
    /*      // Make the POST request to the external API
      axios.post(process.env.REACT_APP_DBNS_API_URL, requestBodyCopy)
        .then(response => {
          const data_arr = response.data.data;
          const newData = Array.isArray(data_arr) ? data_arr : [data_arr];
          //reorder data_arr so that it is displayed backwards
          newData.reverse();
          //loop through newData and check if items have an image_url, if not, set image_url to a random scribble image
          newData.forEach((item) => {
            if (!item.image_url) {
              item.image_url = kellmap;
            }
          });
          //add newData to unitedData
          unitedData = [...unitedData, ...newData];
        })
        .catch(error => {
          setError(error);
          setIsLoading(false);
          console.log(error);
        });
    }

    console.log(unitedData);

    if (dataObject.searchExecuted) {
      //if any item.id of the newData array is in the dataObject.searchResults array, add it to filteredData
      const filteredData = unitedData.filter((item) => {
        return dataObject.searchResults.includes(parseInt(item.id));
      });
      setData(filteredData);
    } else {
      setData(unitedData);
    }
    setIsLoading(false);*/

  };


  //call GeoLocation API to estimate user's location => JSON response with latitude and longitude from https://geolocation-db.com/json/



  const handleCardToggle = () => {
    setIsCardOpen(!isCardOpen);
  };

  const handleConsentAccept = () => {
    setShowConsentDialog(false);
    localStorage.setItem('trackingConsent', true);
  };

  const handleConsentDecline = () => {
    setShowConsentDialog(false);
    localStorage.setItem('trackingConsent', false);
  };


  useEffect(() => {
    // call the functions to populate the select components
    fetchData({
      searchCategories: {
        'counseling': true,
        'products': true,
        'education': true,
        'projects': true,
        'events': true,
      },
      userLocation: [],
      selectedThings: [],
      selectedActivities: [],
      searchResults: [],
      searchExecuted: false,
      reparaturbonus: false,
    });

    const fetchActivities = () => {
      // Make the first POST request to get the key
      axios.post(process.env.REACT_APP_DBNS_API_URL, { "resource": "NLS.CategoryTypeResource", "action": "get", "params": { "id": 13 }, "fields": { "target_type": true, "title": true, "categories": { "title": true } } })
        .then(response => {
          window.activities = [];
          response.data.data.categories.map((activity) => {
            window.activities.push(activity.title);
          });
        })
        .catch(error => {
          console.log(error);
        }
        );
    };
  
    const fetchThings = () => {
      // Make the first POST request to get the key
      axios.post(process.env.REACT_APP_DBNS_API_URL, { "resource": "NLS.CategoryTypeResource", "action": "get", "params": { "id": 4 }, "fields": { "target_type": true, "title": true, "categories": { "title": true } } })
        .then(response => {
          window.things = [];
          response.data.data.map((thing) => {
            thing.depth = 0;
            window.things.push(thing.title);
          });
        })
        .catch(error => {
          console.log(error);
        }
        );
    };

    fetchActivities();
    fetchThings();

    //if not in mobile
    if (isDesktop) {
      setNavHeight(nav_ref.current.clientHeight);
    }

    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
    if (currentStep >= 2 && currentStep <= 5 && selectedItem === null) {
      handleClick({
        "id": "3096"
      }
      );
    }
    if (currentStep >= 6 && selectedItem !== null) {
      setSelectedItem(null);
    }
  }, [currentStep]);

  return (
    <ThemeProvider theme={customTheme}>
      {isDesktop ? (
        <>
          <Grid container style={{ height: "100vh" }} >
            {showLogo && (
              <Grid container direction='column' columns={16} spacing={1} justifyContent="space-between" alignItems="stretch" style={{ borderRight: "0.5px solid grey", marginRight: "1px", marginLeft: "1px", height: "100%" }} id="logo_Box">
                <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "20px" }}>
                  <img src={reusereduce} alt="ReMap Logo" width="13px" />
                </Grid>
                <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "5px" }}>
                  <img src={remap_logo} alt="ReMap Logo" width="50px" />
                </Grid>
              </Grid>
            )}
            <AppContext.Provider value={{ fetchData }}>
              {searchComponent && (
                <Grid item xs style={{ backgroundColor: customTheme.palette.secondary.main, boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.1) 0px 0px 3px 1px", zIndex: 5 }} id="search-Box">
                  <Box container spacing={1} direction="column">
                    <Box item id="search" sx={{ mb: 1 }}>
                      <ResizeObserver
                        onResize={(height) => {
                          setSearchHeight(height.height);
                        }}
                      >
                        <Search mapRef={forwardMapRef} />
                      </ResizeObserver>
                    </Box>
                    <Box item style={{ overflow: 'auto', height: `calc(100vh - ${searchHeight}px - ${navHeight}px - 8px)` }} className="results-list">
                      {!isLoading && !error ? (
                        <>
                          {listIndex === 0 && <ResultsList data={data} handleClick={handleClick} mapRef={forwardMapRef} location={userLocation} />}
                          {listIndex === 1 && <ResultsList data={data} handleClick={handleClick} mapRef={forwardMapRef} />}
                          {listIndex === 2 && <FavoritesList data={data} handleClick={handleClick} mapRef={forwardMapRef} />}
                        </>
                      ) : (
                        <Stack spacing={1}>
                          <Divider />
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              <Skeleton variant="circular" width={60} height={60} />
                            </Grid>
                            <Grid item xs spacing={1}>
                              <Stack spacing={1}>
                                <Skeleton variant="rounded" width={"100%"} height={30} />
                                <Skeleton variant="rounded" width={"100%"} height={60} />
                              </Stack>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              <Skeleton variant="circular" width={60} height={60} />
                            </Grid>
                            <Grid item xs spacing={1}>
                              <Stack spacing={1}>
                                <Skeleton variant="rounded" width={"100%"} height={30} />
                                <Skeleton variant="rounded" width={"100%"} height={60} />
                              </Stack>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              <Skeleton variant="circular" width={60} height={60} />
                            </Grid>
                            <Grid item xs spacing={1}>
                              <Stack spacing={1}>
                                <Skeleton variant="rounded" width={"100%"} height={30} />
                                <Skeleton variant="rounded" width={"100%"} height={60} />
                              </Stack>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              <Skeleton variant="circular" width={60} height={60} />
                            </Grid>
                            <Grid item xs spacing={1}>
                              <Stack spacing={1}>
                                <Skeleton variant="rounded" width={"100%"} height={30} />
                                <Skeleton variant="rounded" width={"100%"} height={60} />
                              </Stack>
                            </Grid>
                          </Grid>
                          <Divider />
                        </Stack>
                      )}

                    </Box>
                  </Box>
                  <BottomNavigation showLabels sx={{ backgroundColor: '#b8b8b8', marginLeft: "-2px" }} ref={nav_ref}>
                    <BottomNavigationAction label="Alle Ergebnisse" icon={<AppsIcon />} onClick={() => {
                      setListIndex(0);
                      setSelectedItem(null);
                    }}
                    />
                    <BottomNavigationAction label="In deiner Nähe" icon={<RadarIcon />} />
                    <BottomNavigationAction label="Favoriten" icon={<FavoriteIcon />} onClick={() => {
                      setListIndex(2);
                      setSelectedItem(null);
                    }}
                      className="favoritesList-Button" />
                  </BottomNavigation>
                </Grid>
              )}
              <Grid item xs={isBack ? 3 : 11} sm={isBack ? 4 : 11} md={isBack ? 5 : 11} lg={isBack ? 7 : 10} xl={isBack ? 8 : 11} style={{ zIndex: 1, padding: "0px", position: "relative" }} id="map_Box">
                {selectedItem && (
                  <Box style={{ position: 'absolute', zIndex: 2000, paddingLeft: "15px", paddingTop: "60px", width: "25vw" }} id="detailed-view-Box">
                    <DetailedView selectedItem={selectedItem} onClose={() => {
                      setSelectedItem(null);
                      forwardMapRef.current.deleteSelectedMarker();
                    }} />
                  </Box>
                )}
                <IconButton onClick={handleBackClick} style={{ position: "absolute", zIndex: 800 }}>
                  {isBack ? <ArrowCircleLeftIcon /> : <ArrowCircleRightIcon />}
                </IconButton>
                <ResultsFilter selectedCategories={selectedCategories} onCategoryToggle={handleCategoryToggle} />
                <Map data={data} ref={forwardMapRef} handleClick={handleClick} />
              </Grid>
              <Grid item style={{ zIndex: 1, boxShadow: "-1px 0px 1px 1px #aaaaaa" }}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "space-between", height: "100%" }}>
                  <List>
                    <ListItem
                      key='add_entry'
                      disablePadding
                      sx={{
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: defaultTheme.palette.style1.main,
                        marginBottom: 1,
                        border: "2px solid #fff",
                        borderRadius: "10px",
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: 'center',
                          px: 2.5,
                          mb: -2,
                          mt: 0
                        }}
                        onClick={() => {
                          window.open('https://daten.nachhaltiges-sachsen.de/registrieren', '_blank');
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            justifyContent: 'center',
                          }}
                        >
                          <AddIcon />
                        </ListItemIcon>
                      </ListItemButton>
                      <ListItemText primary="Eintrag" sx={{ justifyContent: 'center' }} primaryTypographyProps={{ fontSize: '10px', fontWeight: "bold", mb: -1.5 }} />
                      <ListItemText primary="hinzufügen" sx={{ justifyContent: 'center' }} primaryTypographyProps={{ fontSize: '10px', fontWeight: "bold" }} />
                    </ListItem>
                    <ListItem key='suggest_entry' disablePadding sx={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: 'center',
                          px: 2.5,
                          mb: -2,
                          mt: -1
                        }}
                        onClick={() => setEntryFormOpen(true)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            justifyContent: 'center',
                            color: defaultTheme.palette.style1.main
                          }}
                        >
                          <AddIcon />
                        </ListItemIcon>
                      </ListItemButton>
                      <ListItemText primary="Eintrag" sx={{ justifyContent: 'center' }} primaryTypographyProps={{ fontSize: '10px', mb: -1.5 }} />
                      <ListItemText primary="vorschlagen" sx={{ justifyContent: 'center' }} primaryTypographyProps={{ fontSize: '10px' }} />
                      <EntryForm entryFormOpen={entryFormOpen} setEntryFormOpen={setEntryFormOpen} />
                    </ListItem>
                    <Divider />
                    <ListItem key='your_results' disablePadding sx={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: 'center',
                          px: 2.5,
                          mb: -2
                        }}
                        onClick={() => setListIndex(0)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            justifyContent: 'center',
                            color: defaultTheme.palette.style3.main
                          }}
                        >
                          <AppsIcon />
                        </ListItemIcon>
                      </ListItemButton>
                      <ListItemText primary="Deine" sx={{ justifyContent: 'center' }} primaryTypographyProps={{ fontSize: '10px', mb: -1.5 }} />
                      <ListItemText primary="Ergebnisse" sx={{ justifyContent: 'center' }} primaryTypographyProps={{ fontSize: '10px' }} />
                    </ListItem>
                    <ListItem key='near_results' disablePadding sx={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: 'center',
                          px: 2.5,
                          mb: -2
                        }}
                        onClick={() => setListIndex(1)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 'auto',
                            justifyContent: 'center',
                            color: defaultTheme.palette.style3.main
                          }}
                        >
                          <RadarIcon />
                        </ListItemIcon>
                      </ListItemButton>
                      <ListItemText primary="In der Nähe" sx={{ justifyContent: 'center' }} primaryTypographyProps={{ fontSize: '10px' }} />
                    </ListItem>
                    <ListItem key='favorite_results' disablePadding sx={{ display: 'flex', flexDirection: "column", alignItems: "center", marginBottom: 1 }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: 'center',
                          px: 2.5,
                          mb: -2
                        }}
                        onClick={() => setListIndex(2)}
                        className="favoritesList-Button"
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 'auto',
                            justifyContent: 'center',
                            color: defaultTheme.palette.style3.main
                          }}
                        >
                          <FavoriteIcon />
                        </ListItemIcon>
                      </ListItemButton>
                      <ListItemText primary="Favoriten" sx={{ justifyContent: 'center' }} primaryTypographyProps={{ fontSize: '10px' }} />
                    </ListItem>
                    <Divider />
                    <ListItem key='tour_guide' disablePadding sx={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: 'center',
                          px: 2.5,
                          mb: -2
                        }}
                        onClick={startIntro}

                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 'auto',
                            justifyContent: 'center',
                            color: defaultTheme.palette.style2.main
                          }}
                        >
                          <InfoIcon />
                        </ListItemIcon>
                      </ListItemButton>
                      <ListItemText primary="Anleitung" sx={{ justifyContent: 'center' }} primaryTypographyProps={{ fontSize: '10px' }} />
                    </ListItem>
                  </List>
                  <List sx={{ marginBottom: 3, p: 0.1 }}>
                    <ListItem key='imprint' disablePadding sx={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: 'center',
                          px: 2.5,
                          mb: -2,
                          mt: 0
                        }}
                        onClick={() => {
                          window.open('https://remap-sachsen.de/#foerderung', '_blank');
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 'auto',
                            justifyContent: 'center',
                            color: defaultTheme.palette.style1.main
                          }}
                        >
                          <SavingsIcon />
                        </ListItemIcon>
                      </ListItemButton>
                      <ListItemText primary="Förderung" sx={{ justifyContent: 'center' }} primaryTypographyProps={{ fontSize: '10px' }} />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
            </AppContext.Provider>
          </Grid>
          <Dialog
            open={consentDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>
              {"Dürfen wir aufpassen, dass nichts schief geht?"}
            </DialogTitle>
            <DialogContent>
              <Grid container direction='row'>
                <Grid item xs={7} sx={{ pr: -10 }}>
                  <DialogContentText>
                    Toll, dass du zu uns gefunden hast! Gerade sind wir noch in der Pilotphase für die ReMap. Deswegen werkeln im Hintergrund kleine Prozesse, mit denen wir nachvollziehen können, falls etwas schief geht. Wir hoffen, dass du uns dabei unterstützt und uns deine Nutzungsdaten zur Verfügung stellst. Wir versprechen dir, dass wir deine Daten nicht an Dritte weitergeben und sie nur für die ReMap verwenden.
                  </DialogContentText>
                </Grid>
                <Grid item xs={5}>
                  <img src={map_image} alt="ReMap Logo" width="100%" style={{ mt: 1 }} />
                </Grid>
              </Grid>
              <br></br>
              <img src={remap} alt="ReMap Logo" width="100px" style={{ mt: 1 }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConsentDecline}>Ich bin nicht einverstanden.</Button>
              <Button onClick={handleConsentAccept} autoFocus>
                Einverstanden!
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        //mobile from here
        <>
          <Grid container direction="column" style={{ height: "100vh" }}>
            <AppContext.Provider value={{ fetchData }}>
              {listIndex < 3 ? (
                <Grid item style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                  <Box id="map_Box" style={{ gridRowStart: 1, gridColumnStart: 1, zIndex: 3 }}>
                    {selectedItem && (
                      <DetailedMobileView selectedItem={selectedItem} onClose={() => setSelectedItem(null)} />
                    )}
                    <Map data={data} ref={forwardMapRef} handleClick={handleClick} theme={customTheme} />
                  </Box>
                  {preselectedItem && (
                    <Box style={{ position: 'absolute', zIndex: 3, bottom: 135, left: 20, right: 60, height: "20vh" }}>
                      <SmallMobileView selectedItem={preselectedItem} onClose={() => setPreSelectedItem(null)} clickDetailedView={() => handleClick(preselectedItem)} />
                    </Box>
                  )}
                  <Box>
                    <Box style={{ height: "7vh", position: "absolute", top: 20, left: 30, right: 30, zIndex: 10 }} onClick={handleExpandSearch}>
                      <Box sx={{ boxShadow: 2, borderRadius: "20px" }}>
                        <Button variant="contained" startIcon={<SearchIcon />} onClick={handleClickOpen} sx={{ fontFamily: 'Syne', fontWeight: 'bold', width: "100%", height: "100%", backgroundColor: customTheme.palette.primary.main, ':hover': { bgcolor: "#fff", color: customTheme.palette.primary.main }, borderRadius: "20px" }}>
                          Suche nach Produkten, Läden, ...
                        </Button>
                      </Box>
                      <Dialog
                        fullScreen
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                        PaperProps={{
                          style: {
                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                            boxShadow: 'none',
                          },
                        }}
                        sx={{ zIndex: 4000 }}
                      >

                        <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center" sx={{ mb: 2, mt: 1 }}>
                          <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ overflow: 'hidden', mt: 1, mb: 1, ml: 2, height: "80%", width: "80%" }}>
                            <img src={remap} alt="ReMap Logo" width="120px" />
                          </Box>
                          <Box display="flex" justifyContent="center" alignItems="center" sx={{ overflow: 'hidden', backgroundColor: '#fff', p: 1, m: 1, borderRadius: "15px", height: "80%", border: "1px solid #808080" }}>
                            <IconButton onClick={handleClose}>
                              <HighlightOffIcon sx={{ color: "fff" }} />
                            </IconButton>
                          </Box>
                        </Stack>

                        <MobileSearch ref={forwardMapRef} clickEvent={handleClose} style={{ zIndex: 3999 }} />

                      </Dialog>
                    </Box>
                    <Root>
                      <CssBaseline />
                      <Global
                        styles={{
                          '.MuiDrawer-root > .MuiPaper-root': {
                            height: `calc(80% - ${drawerBleeding}px)`,
                            overflow: 'visible',
                            bottom: 50,
                          },
                        }}
                      />
                      <SwipeableDrawer
                        anchor="bottom"
                        open={openMobileResults}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                        swipeAreaWidth={drawerBleeding}
                        disableSwipeToOpen={false}
                        ModalProps={{
                          keepMounted: true,
                        }}
                      >
                        <StyledBox
                          sx={{
                            position: 'absolute',
                            top: openMobileResults ? -56 : -drawerBleeding,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: 'visible',
                            right: 0,
                            left: 0,
                          }}
                        >
                          <Puller />
                          <Typography sx={{ p: 2, color: customTheme.palette.primary.main }}>
                            {Object.keys(data).reduce((totalItems, category) => {
                              return data[category].visible ? totalItems + data[category].data.length : totalItems;
                            }, 0) > 0 ? `${Object.keys(data).reduce((totalItems, category) => {
                              return data[category].visible ? totalItems + data[category].data.length : totalItems;
                            }, 0)} Ergebnisse` : 'Keine Ergebnisse'}
                          </Typography>

                        </StyledBox>
                        <StyledBox
                          sx={{
                            px: 2,
                            pb: 2,
                            height: '100%',
                            overflow: 'auto',
                          }}
                        >
                          {!isLoading && !error ? (
                            <>
                              {listIndex === 0 && <ResultsList data={data} handleClick={handleClick} mapRef={forwardMapRef} location={userLocation} />}
                              {listIndex === 1 && <ResultsList data={data} handleClick={handleClick} mapRef={forwardMapRef} />}
                              {listIndex === 2 && <FavoritesList data={data} handleClick={handleClick} mapRef={forwardMapRef} />}
                            </>
                          ) : (
                            <Stack spacing={1}>
                              {/* For variant="text", adjust the height via font-size */}
                              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                              {/* For other variants, adjust the size with `width` and `height` */}
                              <Skeleton variant="circular" width={40} height={40} />
                              <Skeleton variant="rectangular" width={210} height={60} />
                              <Skeleton variant="rounded" width={210} height={60} />
                            </Stack>
                          )}

                        </StyledBox>
                      </SwipeableDrawer>

                    </Root>
                  </Box>
                </Grid>
              ) : (
                <Grid item>
                  <Card variant="outlined" sx={{ backgroundColor: "#F6F6F6" }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Entdecke die Welt des Wiederverwendens in Sachsen
                      </Typography>
                      <Typography sx={{ fontWeight: "bold", fontSize: 18, color: defaultTheme.palette.style3.main }}>
                        ReMap: Neuer Plan für alte Dinge
                      </Typography>
                      <Typography variant="body2">
                        Schön, dass du zur ReMap gefunden hast! Das ist die Karte für alle, die nachhaltig leben wollen. Hier findest du alle Orte in Sachsen, an denen du Dinge wiederverwenden, reparieren lassen, verschenken, tauschen und nachhaltig kaufen kannst. Du kannst nach Produkten, Läden, Orten und Aktivitäten suchen. Oder du schaust dir einfach alle Ergebnisse an. Viel Spaß beim Stöbern!
                      </Typography>
                    </CardContent>
                  </Card>
                  <List sx={{ m: 1 }}>
                    <ListItem sx={{ ':hover': { bgcolor: "#e9e9e9" }, borderRadius: "10px", backgroundColor: defaultTheme.palette.style3.main, mb: 1 }} onClick={() => { window.open('https://daten.nachhaltiges-sachsen.de/registrieren', '_blank') }}>
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Eintrag hinzufügen (falls du Verein/Firma/Organisation bist)" />
                    </ListItem>
                    <ListItem sx={{ ':hover': { bgcolor: "#e9e9e9" }, border: 2, borderRadius: "10px", borderColor: defaultTheme.palette.style3.main, mb: 1 }} onClick={() => setEntryFormOpen(true)}>
                      <ListItemIcon sx={{ color: defaultTheme.palette.style3.main }}>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Eintrag vorschlagen (falls du uns auf ein Angebot hinweisen magst)" />
                      <EntryForm entryFormOpen={entryFormOpen} setEntryFormOpen={setEntryFormOpen} />
                    </ListItem>
                    <Divider />
                    <ListItem sx={{ ':hover': { bgcolor: "#e9e9e9" }, border: 2, borderRadius: "10px", borderColor: defaultTheme.palette.style1.main, mb: 1 }} onClick={() => { window.open('https://remap-sachsen.de/#foerderung', '_blank') }}>
                      <ListItemIcon sx={{ color: defaultTheme.palette.style1.main }}>
                        <SavingsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Förderung" />
                    </ListItem>
                  </List>
                </Grid>
              )}
              <Grid item>
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 3999 }} elevation={3}>
                  <BottomNavigation showLabels sx={{ backgroundColor: '#e9e9e9' }} ref={nav_ref}>
                    <BottomNavigationAction label="Alle Ergebnisse" icon={<AppsIcon />} onClick={() => {
                      setListIndex(0);
                      setSelectedItem(null);
                      setOpenMobileResults(true);
                    }} />
                    <BottomNavigationAction label="In deiner Nähe" icon={<RadarIcon />} />
                    <BottomNavigationAction label="Favoriten" icon={<FavoriteIcon />} onClick={() => {
                      setListIndex(2);
                      setSelectedItem(null);
                      setOpenMobileResults(true);
                    }} />
                    <BottomNavigationAction label="Mehr" icon={<MoreHorizIcon />} onClick={() => setListIndex(3)} />
                  </BottomNavigation>
                </Paper>
              </Grid>
            </AppContext.Provider>
          </Grid>
          <Backdrop
            sx={{ color: '#fff', zIndex: 9999 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </ThemeProvider >
  );
};

export default Site1;
reportWebVitals();