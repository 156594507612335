import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import AppContext from "../../context/AppContext";
import theme from '../../style/theme';
import { ThemeProvider } from '@mui/material/styles';
import BlockRevealAnimation from 'react-block-reveal-animation';
import reparaturbonus from '../../media/images/reparaturbonus.png';
import {
  Autocomplete, Card, CardActions, CardContent, Button, Typography, TextField, Stack, Fab, Checkbox, Divider, InputAdornment, List, Box, FormControl, InputLabel, Select, MenuItem, Grid, ListItem, IconButton, OutlinedInput, Chip, Snackbar, Slide, Alert, FormGroup, FormControlLabel
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  NearMe as NearMeIcon,
  Search as SearchIcon,
  RestartAlt as ResetIcon,
  RadioButtonUnchecked as CheckBoxOutlineBlankIcon,
  CheckCircle as CheckBoxIcon,
  SignLanguage as SignLanguageIcon,
  PedalBike as PedalBikeIcon,
  LocationOn as LocationOnIcon,
  GpsFixed as GpsFixedIcon,
  NorthEast as NorthEastIcon,
} from '@mui/icons-material';
import './style.css';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import { Menu } from 'react-instantsearch';

const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Search = (handleClose) => {
  const [locations, setLocations] = useState([]);
  const [activities, setActivities] = useState([]);
  const [things, setThings] = useState([]);
  const [selectedUserLocation, setSelectedUserLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [selectedThing, setSelectedThing] = useState([]);
  const { fetchData } = useContext(AppContext);
  const [showTypography, setShowTypography] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [locationQuery, setLocationQuery] = useState('');
  const [querySuggestions, setQuerySuggestions] = useState([]);
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [open, setOpen] = useState(false);
  const [radius, setRadius] = useState(20);
  const [openSorryBar, setOpenSorryBar] = useState(false);
  const [resetQueryField, setResetQueryField] = useState(false);
  const [isReparaturbonusChecked, setIsReparaturbonusChecked] = useState(false);
  const handleReparaturbonusChange = (event) => {
    setIsReparaturbonusChecked(event.target.checked);
  };

  //extended search
  const [selectedCategories, setSelectedCategories] = useState({
    'products': true,
    'education': true,
    'projects': true,
    'events': true,
    'counseling': true
  });

  //daterangepicker
  const [selectedDate, setSelectedDate] = useState(7); // Default value for "Heute"

  const handleDateSelect = (option) => {
    setSelectedDate(option);
  };

  const getSelectedDate = (daysToAdd) => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based index
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const futureDate = new Date(today.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
    const futureYear = futureDate.getFullYear();
    const futureMonth = String(futureDate.getMonth() + 1).padStart(2, '0');
    const futureDay = String(futureDate.getDate()).padStart(2, '0');
    const formattedFutureDate = `${futureYear}-${futureMonth}-${futureDay}`;
    return [formattedDate, formattedFutureDate]; // Format as German date
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTypography(true); // Show Typography component
    }, 3000);

    return () => {
      clearTimeout(timer); // Clear timer when component unmounts
    };
  }, []);

  const handleFetchData = () => {
    //loop through selectedThing array and push the id of each object to a new array
    const selectedThingIds = [];
    selectedThing.forEach((thing) => {
      selectedThingIds.push(thing.id);
    });
    //loop through selectedActivity array and push the id of each object to a new array
    const selectedActivityIds = [];
    selectedActivity.forEach((activity) => {
      selectedActivityIds.push(activity.id);
    });
    if (selectedUserLocation && selectedUserLocation.key !== 0) {
      selectedUserLocation.radius = radius;
    }
    console.log(selectedUserLocation);
    let searchParameters = {
      'q': query,
      'query_by': 'title,short_description,description,categories'
    }

    client.collections('dbns_data')
      .documents()
      .search(searchParameters)
      .then(function (searchResults) {
        const data_ids = [];
        if (searchResults.found === 0) {
          setOpenSorryBar(true);
        } else {
          searchResults.hits.map((hit) => {
            data_ids.push(hit.document.data_id);
          });
        }
        setSearchResults(data_ids);
        fetchData({
          "searchCategories": selectedCategories,
          "userLocation": selectedUserLocation,
          "selectedThings": selectedThingIds,
          "selectedActivities": selectedActivityIds,
          "selectedDateRange": getSelectedDate(selectedDate),
          "searchResults": data_ids,
          "searchExecuted": (query !== '' ? true : false),
          "reparaturbonus": isReparaturbonusChecked
        });
      })
      .catch(function (error) {
        console.log(error)
      })
  };

  //reset all autocomplete fields
  const handleReset = () => {
    setSelectedLocation([]);
    setSelectedActivity([]);
    setSelectedThing([]);
    setQuery('');
    //reset query field by setting resetQueryField to other than the current value
    setResetQueryField(!resetQueryField);
    setRadius(20);
  };

  const handleRadiusChange = (event) => {
    setRadius(event.target.value);
  };

  useEffect(() => {
    fetchLocations();
    fetchActivities();
    fetchThings();
  }, []);

  const fetchLocations = () => {
    // Make the first POST request to get the key
    axios.post(process.env.REACT_APP_DBNS_API_URL, { "resource": "NLS.CategoryTypeResource", "action": "get", "params": { "id": 5 }, "fields": { "target_type": true, "title": true, "categories": { "title": true } } })
      .then(response => {
        setLocations(response.data.data.categories);
      })
      .catch(error => {
        console.log(error);
      }
      );
  };

  const fetchActivities = () => {
    // Make the first POST request to get the key
    axios.post(process.env.REACT_APP_DBNS_API_URL, { "resource": "NLS.CategoryTypeResource", "action": "get", "params": { "id": 13 }, "fields": { "target_type": true, "title": true, "categories": { "title": true } } })
      .then(response => {
        setActivities(response.data.data.categories);
        window.activities = [];
        response.data.data.categories.map((activity) => {
          window.activities.push(activity.title);
        });
      })
      .catch(error => {
        console.log(error);
      }
      );
  };

  const fetchThings = () => {
    // Make the first POST request to get the key
    axios.post(process.env.REACT_APP_DBNS_API_URL, { "resource": "NLS.CategoryResource", "action": "list", "params": { "category_type_key": "products" }, "fields": { "title": true, "sub_categories": { "title": true } } })
      .then(response => {
        window.things = [];
        //add depth to each thing, depth = 0 for main categories, depth = 1 for subcategories
        response.data.data.map((thing) => {
          thing.depth = 0;
          window.things.push(thing.title);
        });
        setThings(response.data.data);
        if (response.data.data[12].sub_categories.length > 0) {
          response.data.data[12].sub_categories.map((sub_category) => {
            sub_category.depth = 1;
            setThings((prev) => {
              return [...prev, sub_category];
            });
          });
        }

      })
      .catch(error => {
        console.log(error);
      }
      );
  };

  const handleUserLocationChange = (event, newValue) => {
    if (newValue != null) {
      setSelectedUserLocation(newValue);
    } else {
      setSelectedUserLocation([]);
    }
  };


  const handleLocationChange = (event, newValue) => {
    setSelectedLocation(newValue);
  };

  const handleActivityChange = (event, newValue) => {
    setSelectedActivity(newValue);
  };

  const handleThingChange = (event, newValue) => {
    setSelectedThing(newValue);
  };

  const handleUserLocation = () => {
    setSelectedUserLocation({ label: "Mein Standort", key: 1 });
  };

  const getQuerySuggestions = (value) => {
    let searchParameters = {
      'q': value,
      'query_by': 'q',
    }

    client.collections('dbns_data_queries')
      .documents()
      .search(searchParameters)
      .then(function (searchResults) {
        setQuerySuggestions(searchResults.hits);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleQueryChange = (event, value) => {
    setQuery(value);
    getQuerySuggestions(value);
  };

  const handleSearch = async (event) => {
    const url = `https://photon.komoot.io/api/?q=${locationQuery}&lang=de&limit=5`;
    try {
      const response = await axios.get(url);
      const results = response.data.features.map((feature) => {
        const key = feature.id || feature.properties.osm_id;
        let label = feature.properties.name;
        if (feature.properties.street && feature.properties.housenumber) {
          label += `, ${feature.properties.street} ${feature.properties.housenumber}`;
        }
        if (feature.properties.city) {
          label += `, ${feature.properties.city}`;
        }
        label += `, ${feature.properties.country}`;
        return {
          key,
          label,
          latitude: feature.geometry.coordinates[1],
          longitude: feature.geometry.coordinates[0],
        };
      });
      setLocations(results);
      setOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const Typesense = require('typesense')

  let client = new Typesense.Client({
    'nodes': [{
      'host': 'remap.rotter-services.de',
      'port': 443,
      'protocol': 'https'
    }],
    'apiKey': '4fbc6ebc-1de2-4b75-bc76-1480294d1b5d',
    'connectionTimeoutSeconds': 2
  });

  const searchTypesenseDatabase = () => {
    let searchParameters = {
      'q': query,
      'query_by': 'title,short_description,description,categories'
    }

    client.collections('dbns_data')
      .documents()
      .search(searchParameters)
      .then(function (searchResults) {
        const data_ids = [];
        if (searchResults.found === 0) {
          setOpenSorryBar(true);
        } else {
          searchResults.hits.map((hit) => {
            data_ids.push(hit.document.data_id);
          });
        }
        setSearchResults(data_ids);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSorryBar(false);
  };


  return (
    <div className="search-root">
      <div className="search-container">
        <Stack spacing={1} sx={{ paddingTop: "5px", paddingLeft: "5px", paddingRight: "5px" }}>
          <Box sx={{ overflow: 'hidden', backgroundColor: '#ffc53b', padding: '5px', borderRadius: "15px", border: "1px solid #ffb400" }} className="searchbar-stack">
            <Grid container>
              <Grid item xs={7}>
                <Autocomplete
                  freeSolo
                  id="photon_complete"
                  noOptionsText={'Gib einen Ort ein oder klick das GPS Symbol'}
                  options={locations}
                  value={selectedUserLocation}
                  renderOption={(props, option) => {
                    let icon = null;
                    if (option.key === 1) {
                      icon = <LocationOnIcon />;
                    }
                    return (
                      <List {...props} style={{ fontSize: "0.8em" }} size="small">
                        {icon}
                        {option.label}
                      </List>
                    );
                  }}

                  onInputChange={(event, newValue) => {
                    handleSearch();
                  }
                  }
                  onChange={handleUserLocationChange}
                  size="small"
                  variant="standard"
                  sx={{
                    ".MuiInputBase-input": {
                      border: "0px solid",
                      height: "18px",
                    },
                    ".MuiInputBase-root": {
                      backgroundColor: "#FFF9EB",
                      borderRadius: "6px",
                      padding: "5px"
                    },
                    ".MuiInputLabel-root": {
                      fontWeight: 'bold',
                      fontFamily: 'Syne',
                      fontSize: "1.1em",
                      color: "#000",
                    },
                    ".MuiChip-root": {
                      height: "24px",
                      borderRadius: "12px",
                      fontSize: "11px",
                      backgroundColor: "#3f51b5",
                      color: "#fff"
                    },
                    ".MuiChip-root .MuiChip-label": {
                      paddingLeft: "8px",
                      paddingRight: "8px"
                    },
                    ".MuiChip-root:not(:first-of-type)": {
                      marginLeft: "4px"
                    }

                  }}
                  getOptionLabel={(options) => {
                    if (options.key !== undefined) {
                      if (options.key === 1) {
                        return "Mein Standort"
                      } else {
                        return options.label
                      }
                    } else {
                      return options.label ? options.label : "" //options
                    }
                  }}

                  renderInput={(params) => <TextField {...params} sx={{ fontSize: "0.8em", padding: "0px" }} onChange={(event) => setLocationQuery(event.target.value)} variant="standard" label="WO BIST DU?" size="small" InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <NearMeIcon />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    )
                  }} />}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton edge="end" aria-label="delete" sx={{ marginLeft: "1px", marginTop: "10px" }} onClick={handleUserLocation}>
                  <GpsFixedIcon />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="standard" size="small" sx={{
                  ".MuiInputLabel-root": {
                    fontFamily: 'Syne',
                    fontWeight: 'bold',
                    fontSize: "0.7em",
                    marginBottom: "-5px",
                  },
                  ".MuiInputBase-input": {
                    backgroundColor: "#FFF9EB",

                  },
                  ".MuiInputBase-root": {
                    backgroundColor: "#FFF9EB",
                    borderRadius: "6px",
                    padding: "3px",
                  },
                  marginTop: "-2px",
                  ml: 1,
                  minWidth: 100,

                }}>
                  <InputLabel>Radius</InputLabel>
                  <Select onChange={handleRadiusChange} value={radius}>
                    <MenuItem value={2}>2 km</MenuItem>
                    <MenuItem value={5}>5 km</MenuItem>
                    <MenuItem value={10}>10 km</MenuItem>
                    <MenuItem value={20}>20 km</MenuItem>
                    <MenuItem value={50}>50 km</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ overflow: 'hidden', backgroundColor: '#bdd85b', padding: '5px', borderRadius: "15px", border: "1px solid #9ab82c" }}>
            <Autocomplete
              id="things_select"
              multiple
              disablePortal
              disableCloseOnSelect
              options={things}
              value={selectedThing}
              onChange={handleThingChange}
              noOptionsText={'Dieses Ding gibt es leider nicht :('}
              renderOption={(props, things, { selected }) => (
                <List {...props} style={{ fontSize: "0.8em", padding: things.depth * 2 }} size="small">
                  <Checkbox
                    icon={uncheckedIcon}
                    checkedIcon={checkedIcon}
                    sx={{ ml: 2 * things.depth, transform: "scale(0.8)" }}
                    checked={selected}
                  />
                  {things.title}
                </List>
              )}
              size="small"
              variant="standard"
              sx={{
                ".MuiInputBase-input": {
                  border: "0px solid",
                  height: "18px",
                },
                ".MuiInputBase-root": {
                  backgroundColor: "#F8FBEE",
                  borderRadius: "6px",
                  padding: "5px"
                },
                ".MuiInputLabel-root": {
                  fontWeight: 'bold',
                  fontFamily: 'Syne',
                  fontSize: "0.9em",
                  color: "#000"
                },
                ".MuiChip-root": {
                  height: "24px",
                  borderRadius: "12px",
                  fontSize: "11px",
                  backgroundColor: "#3f51b5",
                  color: "#fff"
                },
                ".MuiChip-root .MuiChip-label": {
                  paddingLeft: "8px",
                  paddingRight: "8px"
                },
                ".MuiChip-root:not(:first-of-type)": {
                  marginLeft: "4px"
                }

              }}
              getOptionLabel={(things) => things.title}
              renderInput={(params) => <TextField {...params} variant="standard" size="small" label="WAS HAST ODER SUCHST DU?" InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <PedalBikeIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                )
              }} />}
            />
          </Box>
          <Box sx={{ overflow: 'hidden', backgroundColor: '#00aea3', padding: '5px', borderRadius: "15px", border: "1px solid #00948b" }}>
            <Autocomplete
              id="activities_select"
              multiple
              disablePortal
              disableCloseOnSelect
              options={activities}
              value={selectedActivity}
              noOptionsText={'Diese Aktivität gibt es leider nicht :('}
              onChange={handleActivityChange}
              renderOption={(props, activities, { selected }) => (
                <List {...props} style={{ fontSize: "0.8em", padding: 0 }} size="small">
                  <Checkbox
                    icon={uncheckedIcon}
                    checkedIcon={checkedIcon}
                    style={{
                      transform: "scale(0.8)"
                    }}
                    checked={selected}
                  />
                  {activities.title}
                </List>
              )}
              size="small"
              variant="standard"
              sx={{
                ".MuiInputBase-input": {
                  border: "0px solid",
                  height: "18px",
                },
                ".MuiInputBase-root": {
                  backgroundColor: "#E5F6F5",
                  borderRadius: "6px",
                  padding: "5px"
                },
                ".MuiInputLabel-root": {
                  fontWeight: 'bold',
                  fontFamily: 'Syne',
                  fontSize: "0.9em",
                  color: "#000"
                },
                ".MuiChip-root": {
                  height: "24px",
                  borderRadius: "12px",
                  fontSize: "11px",
                  backgroundColor: "#3f51b5",
                  color: "#fff"
                },
                ".MuiChip-root .MuiChip-label": {
                  paddingLeft: "8px",
                  paddingRight: "8px"
                },
                ".MuiChip-root:not(:first-of-type)": {
                  marginLeft: "4px"
                },
              }}
              getOptionLabel={(activities) => activities.title}
              renderInput={(params) => <TextField {...params} variant="standard" size="small" label="WAS WILLST DU DAMIT MACHEN?" InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SignLanguageIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                )
              }} />}
            />
          </Box>
          <Box sx={{ overflow: 'hidden', backgroundColor: '#ffc53b', padding: '5px', borderRadius: "15px", border: "1px solid #ffb400" }}>
            <Autocomplete
              id="query_select"
              disablePortal
              freeSolo
              key={resetQueryField}
              options={querySuggestions}
              noOptionsText={'Go for it!'}
              onInputChange={handleQueryChange}
              size="small"
              renderOption={(props, querySuggestion) => {
                return (
                  <List {...props} style={{ fontSize: "0.8em" }} size="small">
                    <NorthEastIcon sx={{ fontSize: "small", mr: 1 }} />
                    {querySuggestion.document.q}
                  </List>
                );
              }}
              variant="standard"
              sx={{
                ".MuiInputBase-input": {
                  border: "0px solid",
                  height: "18px",
                },
                ".MuiInputBase-root": {
                  backgroundColor: "#FFF9EB",
                  borderRadius: "6px",
                  padding: "5px"
                },
                ".MuiInputLabel-root": {
                  fontWeight: 'bold',
                  fontFamily: 'Syne',
                  fontSize: "0.9em",
                  color: "#000",
                },
                ".MuiChip-root": {
                  height: "24px",
                  borderRadius: "12px",
                  fontSize: "11px",
                  backgroundColor: "#3f51b5",
                  color: "#fff"
                },
                ".MuiChip-root .MuiChip-label": {
                  paddingLeft: "8px",
                  paddingRight: "8px"
                },
                ".MuiChip-root:not(:first-of-type)": {
                  marginLeft: "4px"
                }

              }}
              getOptionLabel={(querySuggestions) => querySuggestions.document.q}
              renderInput={(params) => <TextField {...params} variant="standard" size="small" label="FREITEXTSUCHE" InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                )
              }} />}
            />

          </Box>
          {selectedCategories.events &&
            <Box sx={{ overflow: 'hidden', backgroundColor: '#bdd85b', padding: '5px', borderRadius: "15px", border: "1px solid #9ab82c" }}>
              <InputLabel sx={{ fontFamily: 'Syne', fontWeight: 'bold', fontSize: "0.7em", color: "#000", }}>ZEITRAUM</InputLabel>
              <div style={{ display: 'flex', padding: "4px", flexWrap: "wrap", backgroundColor: "#F8FBEE", borderRadius: "6px", gap: "2px" }}>
                {[1, 2, 7, 14, 30, 60].map((days) => (
                  <Chip
                    key={days}
                    label={`${days === 1 ? 'Heute' : ''} ${days === 2 ? 'Morgen' : ''} ${days === 7 ? 'Diese Woche' : ''} ${days === 14 ? 'Bis nächste Woche' : ''} ${days === 30 ? 'Dieser Monat' : ''} ${days === 60 ? 'Nächster Monat' : ''}`}
                    onClick={() => handleDateSelect(days)}
                    variant={selectedDate === days ? 'filled' : 'outlined'}
                    color={selectedDate === days ? 'primary' : 'default'}
                    clickable
                  />
                ))}
              </div>
            </Box>
          }
        </Stack>
        <Grid container spacing={2} sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <Grid item xs={4}>
            <Stack direction="row" spacing={2} sx={{ paddingLeft: "10px", justifyContent: "left" }}>
              <FormControlLabel control={<Checkbox checked={isReparaturbonusChecked} onChange={handleReparaturbonusChange}/>} label={<img src={reparaturbonus} height="30px" />} />
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <Stack direction="row" spacing={2} sx={{ paddingRight: "5px", justifyContent: "right" }}>
              <Fab variant="extended" size="medium" sx={{ backgroundColor: "#b8b8b8", fontFamily: 'Syne', fontWeight: 'bold', fontSize: '12px' }} onClick={handleReset}>
                <ResetIcon sx={{ mr: 1 }} />
                Von vorn
              </Fab>
              <Fab variant="extended" size="medium" color="primary" aria-label="add" onClick={handleFetchData} sx={{ fontFamily: 'Syne', fontWeight: 'bold', fontSize: '12px' }}>
                <SearchIcon sx={{ mr: 1 }} />
                Suchen
              </Fab>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <Snackbar open={openSorryBar} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: "top", horizontal: "right" }} TransitionComponent={Slide}>
        <Alert onClose={handleAlertClose} severity="info" sx={{ width: '100%' }}>
          Wir haben wirklich versucht, den Gegenstand/Ort deiner Begierde zu finden. Leider ohne Erfolg. Sorry!
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Search;
